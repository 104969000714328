<script>
export default {
  name: 'FacebookAuthLayout',
  mounted () {
    this.authenticateUser()
  },
  data () {
    return {
    }
  },
  methods: {
    authenticateUser () {

    }
  }
}
</script>
